<template>
    <div
        title="Voice Search"
        id="speech-input-icon-wrapper"
        class="inputIconWrapper"
        v-if="isVoiceSupported"
    >
        <div class="speech-icon" @click="startVoice">
            <img id="speech-input" src="/img/micrphone_icon.svg" />
        </div>
        <div v-if="showOverlay" class="speech-overlay">
            <div class="close-speecha">
                <span class="close-speech" @click="onClose">
                    <i class="pi pi-times" aria-hidden="true"></i>Close
                </span>
            </div>
            <div class="speech-ctacopy">
                <span id="miconcta" class="animate-flicker"
                    ><img id="speechui-icon" src="/img/micrphone_icon.svg"
                /></span>
                What would you like to search for?
            </div>
        </div>
    </div>
</template>

<script>
import { isVoiceSupported } from "../../utility";

export default {
    data() {
        return {
            text: "",
            isProgress: false,
            speechRecognition:
                window.SpeechRecognition || window.webkitSpeechRecognition,
            speechGrammarList:
                window.SpeechGrammarList || window.webkitSpeechGrammarList,
            // speechRecognitionEvent: SpeechRecognitionEvent || webkitSpeechRecognitionEvent,
            grammar: "#JSGF V1.0; grammar phrase; public <phrase> =;",
            lang: "en-US",
            notFound: false,
            recognition: null,
            isVoiceSupported,
        };
    },
    mounted() {},
    methods: {
        startVoice() {
            this.notFound = false;
            if (!this.recognition) {
                this.recognition = new this.speechRecognition();
                const speechRecognitionList = new this.speechGrammarList();
                speechRecognitionList.addFromString(this.grammar, 1);
                this.recognition.grammars = speechRecognitionList;
                this.recognition.lang = this.lang;
                this.recognition.interimResults = false;
                this.recognition.maxAlternatives = 10;
                this.recognition.onresult = this.onSpeechResult;
                this.recognition.onspeechend = this.onSpeechEnd;
                this.recognition.onerror = this.onSpeechError;
            }
            this.recognition.start();
            this.isProgress = true;
        },
        onClose(e) {
            this.recognition.abort();
            this.isProgress = false;
        },
        onSpeechEnd(e) {
            this.isProgress = false;
            this.recognition.stop();
        },
        onSpeechError(e) {
            this.isProgress = false;
        },
        onSpeechResult(e) {
            if (e.results) {
                const speechText = e.results[0][0].transcript.toLowerCase();
                this.$emit("speechResult", speechText);
            } else {
                this.notFound = true;
            }
        },
    },
    computed: {
        showOverlay() {
            return this.isProgress;
        },
    },
};
</script>

<style>
@import "./speech.css";
</style>
