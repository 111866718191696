<template>
    <div class="footer-root">
        <div id="footer-wrapper" class="footerWrapper">
            <div id="footer-logo-wrapper" class="footerLogoWrapper">
                <div id="logo-img-wrapper" class="logoImgWrapper">
                    <img id="logo-img" class="logoImg" src="/img/logo.svg" />
                </div>
                <div id="logo-text-wrapper" class="logoTextWrapper"></div>
            </div>
            <div id="footer-links-wrapper" class="footerLinksWrapper">
                <div
                    id="footer-links-inner-wrapper"
                    class="footerLinksInnerWrapper"
                >
                    <a href="/contact-us.html" class="footerLink">Contact Us</a>

                    <a href="/privacy-policy.html" class="footerLink"
                        >Privacy Policy</a
                    >

                    <a href="/terms-of-service.html" class="footerLink"
                        >Terms of Service</a
                    >
                    <a href="/unsubscribe.html" class="footerLink"
                        >Unsubscribe</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "searchables-footer",
    props: ["mobile"],
};
</script>

<style>
body,
html {
    height: 100%;
}
body {
    margin: 0px;
    padding: 0px;
    font-family: Arial, sans-serif;
}

.root {
    display: grid;
    grid-template-rows: auto 1fr 55px;
    height: 100% !important;
    min-height: 100% !important;
    position: relative;
}

.footHeaderWrapper {
    grid-row-start: 1;
}

img#foot-logo-img {
    height: 50px;
    padding: 20px;
}

.footBodyWrapper {
    grid-row-start: 2;
    width: 960px;
    margin: 0 auto;
    color: #3d3d3d;
}

.footFooterWrapper {
    grid-row-start: 3;
    border-top: 1px solid #d3d3d3;
    max-height: 80px;
}

.footerRoot {
    display: block;
    max-height: 60px !important;
    padding: 6px;
}
.footerLogoWrapper {
    display: grid;
    grid-column-start: 1;
    grid-template-columns: auto auto auto;
}
.logoImgWrapper {
    grid-column-start: 1;
    width: 0;
}
.logoTextWrapper {
    grid-column-start: 2;
    padding-top: 15px;
}
.footerLinksWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: right;
    grid-column-start: 2;
    padding-top: 15px;
}
.footerLinksInnerWrapper {
    grid-column-start: 2;
    display: inline-flex;
}
.footerLink {
    margin-right: 25px;
    cursor: pointer;
    text-align: center;
}
.footerWrapper {
    display: grid;
    grid-template-columns: 485px auto;
    font-size: 0.8em;
}

.logoImg {
    width: 135px;
    padding: 10px 0px 0px 20px;
    filter: grayscale(1);
}

#footer-links-inner-wrapper a {
    text-decoration: none;
    color: #3f3f3f;
}

#footer-links-inner-wrapper a:hover {
    color: #0857e8;
}
</style>
