export function getUserConsentDate(today = false) {
    const date = () => {
        const now = new Date(Date.now());
        let day = now.getDate();
        if (day < 10) day = `0${day}`;
        let month = now.getMonth() + 1;
        if (month < 10) month = `0${month}`;
        let year = now.getFullYear() % 100; // get last two digits
        if (year < 10) year = `0${year}`;

        return `${month}${day}${year}`;
    };
    if (today) return date();

    let userInstallDate = localStorage.getItem("searchables_UserConsentDate");
    if (userInstallDate) return userInstallDate;

    userInstallDate = date();
    localStorage.setItem("searchables_UserConsentDate", userInstallDate);
    return userInstallDate;
}

export function getTypeTag(typeTag, setTagInUrl = false) {
    if (typeTag) {
        const basicTypeTagRegex = /Y.*_F.*/;
        if (basicTypeTagRegex.test(typeTag)) {
            // check for date attached
            const typeParts = typeTag.split("_");
            if (typeParts.length < 4 || typeParts[3] == "") {
                typeTag += `${
                    typeParts[3] == "" ? "" : "_"
                }${getUserConsentDate(true)}`;
            }
        }
        if (setTagInUrl) {
            const url = new URL(window.location.href);
            url.searchParams.set("type", typeTag);
            window.history.replaceState({}, null, url.toString());
        }
    }
    return typeTag;
}
