import { getTypeTag } from "@/common";
// <!-- Google Analytics -->
(function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    (i[r] =
        i[r] ||
        function () {
            (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
})(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
);

const GA_ID = "G-V2VWEEPC4W";
const GTM_ID = "GTM-PZTBSZT";

ga("create", GA_ID, "auto");
if (GTM_ID) {
    // GTM
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l != "dataLayer" ? `&l=${l}` : "";
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", GTM_ID);
}

export function trackGA(cat, action, label) {
    ga("send", {
        hitType: "event",
        eventCategory: cat,
        eventAction: action,
        eventLabel: label,
    });
}

// Type 1=search 2=ad click 3=organic click
export function yahooXMLClickEvent(type, searchInfo) {
    fetch(
        `/xml/ping.php?q=${searchInfo.keyword}&source_tag=${
            searchInfo.source_tag
        }&type_tag=${getTypeTag(searchInfo.type_tag)}&site=${
            searchInfo.site
        }&etype=${type}&user_country=${searchInfo.user_country}&market=${
            searchInfo.market
        } &search_action=${searchInfo.search_action || "default_search"}`
    );
}

export function pageview() {
    ga("send", "pageview");
}
// <!-- End Google Analytics -->
