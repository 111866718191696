const CURRENT_HOST = `${new URL(window.location).origin}`;

function getTopDomain() {
    if (window.location.href) {
        try {
            const url = new URL(window.location.href);
            if (url.origin) return url.origin;
        } catch (e) {}
    }
    return null;
}

function getAbsoluteDomain(path) {
    if (getTopDomain()) {
        if (path && path.charAt(0) == "/") return getTopDomain() + path;
        return `${getTopDomain()}/${path}`;
    }
}

function isRelativeUrl(url) {
    const check = /^https?:\/\//i;
    return !check.test(url);
}

function getYHSFrameUrl() {
    const hsimp =
        CURRENT_HOST.split(".").length > 2
            ? "yhs-searchables"
            : "yhs-searchables2";
    return `https://search.yahoo.com/yhs/search?hspart=tro&hsimp=${hsimp}`;
}

const YAHOO_YHS_FRAME_URL = getYHSFrameUrl();

const YAHOO_SEARCH_API_URL = `/api/get.php`;

export const isVoiceSupported =
    navigator.userAgent.includes("Safari") ||
    navigator.userAgent.includes("Chrome");

export {
    getAbsoluteDomain,
    isRelativeUrl,
    YAHOO_YHS_FRAME_URL,
    YAHOO_SEARCH_API_URL,
    CURRENT_HOST,
};
